import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35e58747"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "body-text-regular-14 lh-20 mt-2px distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "singleSelect",
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      (_ctx.description)
        ? (_openBlock(), _createBlock(_component_tm_stylized_text, {
            key: 0,
            class: "body-text-regular-14 lh-20 neutral--text mb-4",
            text: _ctx.description
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_form_line, { label: _ctx.label }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.val,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
            type: "select",
            clearable: false,
            options: _ctx.options,
            placeholder: _ctx.placeholder
          }, null, 8, ["modelValue", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label"]),
      (_ctx.hint)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true)
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "blue"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}